import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState:{uid:null|string,is_email_verified:boolean} ={
  uid:null,
  is_email_verified:false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
      on_user_login:(state,action:PayloadAction<{uid:string,is_email_verified:boolean}>)=>{
          // state.uid = action.payload.uid;
          // state.is_email_verified = action.payload.is_email_verified;
      },
     
  },
});

// Action creators are generated for each case reducer function
export const { on_user_login } = authSlice.actions;

export default authSlice.reducer;



import React from 'react';



import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import RequireAuth from './utils/wrappers/require_auth';
import Fallback from './common/components/fallback';
import Landing from './screens/landing';


const Home = React.lazy(()=>import('./screens/home'));


const firebaseConfig = {
  apiKey: "AIzaSyCrN7PhEyDwvIgjSgNFts1o0wluoq_6BnY",
  authDomain: "composer-af59b.firebaseapp.com",
  projectId: "composer-af59b",
  storageBucket: "composer-af59b.appspot.com",
  messagingSenderId: "941679828958",
  appId: "1:941679828958:web:044631439ef13a32bbc74f",
  measurementId: "G-YV2SP4GWJH"
};

const app = initializeApp(firebaseConfig);
const fire = getFirestore(app);
getAnalytics(app);



const App = () => {
  return (
    <BrowserRouter>
        <div className='w-full h-full'>
          <AnimatedRoutes />
        </div>
    </BrowserRouter>
  )
};


const AnimatedRoutes = () => {

  return (
      <AnimatePresence mode='wait'>
          <Routes>
                 <Route path='*' element={AuthWithSuspenseWrapper(Landing)} />
                 <Route path='/generate' element={AuthWithSuspenseWrapper(Home)} />
           </Routes>
      </AnimatePresence>
  )
};


function AuthWithSuspenseWrapper(Component:React.ComponentType<any>|null):any {
  const COMP = () => (
      <RequireAuth>
          <React.Suspense fallback={<Fallback/>}>
                  {!Component?null:<Component />}
          </React.Suspense>
      </RequireAuth>

  );
  return <COMP />;
};



export default App;


export{
  fire
}
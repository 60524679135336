// import React from 'react';
// import { TwitterTweetEmbed } from 'react-twitter-embed';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {m} from 'framer-motion';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
// https://framerusercontent.com/images/gs2YqnOXrs8TYMFXP4cvIxyWhw.svg
// src="https://framerusercontent.com/images/5WS9XTevnx8aN8GC5p9H4nPwg.svg"

const transition = {
    type: "spring",
    bounce: 0.4,
    duration: 0.8,
}

const Landing = () => {
    const _bottom_ref = useRef(null);

    const navigate = useNavigate();

    const _on_click = () => {
         navigate('/generate');
    }

    return (
        <div className='w-full h-full bg-white ie overflow-scroll'>
                {/* <Header /> */}

                <div className='w-full min-h-[100vh] flex flex-col items-center justify-center overflow-hidden pb-5  relative z-10 pt-40'> 
                        <img alt='' className='w-10 h-10 absolute top-6' src={require('../../assests/logo/logo.png')}/>

                        <m.div initial={{opacity:0}} animate={{opacity:1,transition:{delay:.2,duration:.5}}} transition={transition} className='flex flex-col z-10 w-[95%] lg:w-[64%] 2xl:w-[40%] items-center justify-center pb-10 relative'>
                                <span className='font-rubik font-bold text-4xl lg:text-5xl 2xl:text-7xl xl:leading-[3.5rem]  2xl:leading-[5rem] text-slate-800 text-center'>#Tweet smarter,<br/>Not harder.</span>
                                <span className='font-rubik font-normal text-sm lg:text-xl text-center w-[90%] xl:w-[70%] mt-2.5 xl:mt-7 text-slate-500'>Discover the Power of AI-Driven Tweet Generation to Increase your Social Media Influence.</span>
                                {/* and Drive Results */}

                                <div className='flex flex-col md:flex-row w-full px-5 items-center mt-5 justify-center space-x-3'>
                                    {/* <input className='w-full md:w-[50%] xl:w-[50%] border bg-white shadow-md rounded-full px-8 py-3 font-raleway text-base xl:text-xl ring-0 outline-0' placeholder='Whats in your mind?'/> */}
                                   
                                    <m.div onClick={_on_click} whileHover={{scale:1.05}} initial={{scale:1}} className='rounded-full px-10 xl:px-20 py-3 cursor-pointer md:py-3 border border-[#27ae60] mt-3 md:mt-0 bg-[#27ae60] shadow-md '>
                                        <span className='font-raleway font-bold  text-base xl:text-xl text-white'>Try it yourself 🪄</span>
                                    </m.div>
                                </div>

                                {/* <span className='mt-2 font-rubik text-slate-500 text-xs w-[90%] lg:text-base text-center'>You can continue by clicking generate without entering anything.</span> */}
                                
                                {/* <div className='absolute -left-5 lg:left-auto xl:top-auto -top-10 lg:-top-20 lg:-right-[25%] xl:-right-[15%] 2xl:-right-[30%]'>
                                     <img className=' w-32 h-32 lg:w-80 lg:h-80  2xl:w-96 2xl:h-96' src='https://framerusercontent.com/images/gs2YqnOXrs8TYMFXP4cvIxyWhw.svg'/>
                                </div>

                                <div className='absolute  xl:flex lg:-bottom-10 xl:bottom-auto lg:-left-[15%] xl:-left-[10%] 2xl-left-[25%] bottom-12'>
                                     <img className='w-56 h-56' src='https://framerusercontent.com/images/5WS9XTevnx8aN8GC5p9H4nPwg.svg'/>
                                </div> */}

                                {/* <div className='px-5 xl:px-8  py-1.5 xl:py-2 bg-gray-200 bg-opacity-75 rounded-full mt-24'>
                                    <span className='font-rubik text-sm xl:text-base font-medium text-slate-800'>In Action 💡</span>
                                </div> */}
                        </m.div>

                        <div className='absolute blur-md -z-10 top-[5%] md:top-auto scale-150 opacity-70 '>
                                <img alt='' className='rotate' src={require('../../assests/bg/bg.webp')}/>
                        </div>
                        <div className='px-5 xl:px-8  py-1.5 xl:py-2 mt-5 bg-gray-200 bg-opacity-75 rounded-full mb-3'>
                                <span className='font-rubik text-sm xl:text-base font-medium text-slate-800'>Examples speak louder 📢</span>
                        </div>
                        <div className='min-h-[50vh] flex flex-col md:flex-row  md:justify-center md:items-start self-start md:self-center px-5  xl:px-0 w-full xl:w-[75%] 2xl:w-[60%]'>
                             <ResponsiveMasonry   className='w-full' columnsCountBreakPoints={{350: 1, 750:2, 1024:3}}>
                                <Masonry className='space-x-5'>
                                        <div>
                                          <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Unleash your inner coding ninja and unlock a world of endless possibilities. 💻✨ <a href="https://twitter.com/hashtag/Coding?src=hash&amp;ref_src=twsrc%5Etfw">#Coding</a> <a href="https://twitter.com/hashtag/SoftwareEngineering?src=hash&amp;ref_src=twsrc%5Etfw">#SoftwareEngineering</a></p>&mdash; Kiruba (@kiruba_2) <a href="https://twitter.com/kiruba_2/status/1677772889318367232?ref_src=twsrc%5Etfw">July 8, 2023</a></blockquote>
                                        </div>
                                     
                                        <div>
                                            <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Just developed a sleek website today using <a href="https://twitter.com/hashtag/TailwindCSS?src=hash&amp;ref_src=twsrc%5Etfw">#TailwindCSS</a> and wow, what a game-changer! 🚀💥<br/> <br/>Styling used to be a headache, but Tailwind&#39;s intuitive classes made it a breeze. Saved me so much time and frustration! Can&#39;t imagine going back to the old ways. <a href="https://twitter.com/tailwindcss?ref_src=twsrc%5Etfw">@tailwindcss</a>…</p>&mdash; Kiruba (@kiruba_2) <a href="https://twitter.com/kiruba_2/status/1679572766561796096?ref_src=twsrc%5Etfw">July 13, 2023</a></blockquote>
                                        </div>
                                        <div>
                                            <blockquote className="twitter-tweet"><p lang="en" dir="ltr">🚀 Google Teachable Machine makes machine learning accessible to all! 🤩 No coding or ML experience needed! 🎓 <br/><br/>Train models to recognize images, sounds, or poses with ease. 🖼️🔊💃<a href="https://t.co/hSAXzYwx7j">https://t.co/hSAXzYwx7j</a> <a href="https://t.co/jBiX3Awyvq">pic.twitter.com/jBiX3Awyvq</a></p>&mdash; Kiruba (@kiruba_2) <a href="https://twitter.com/kiruba_2/status/1678499154618724352?ref_src=twsrc%5Etfw">July 10, 2023</a></blockquote>
                                        </div>

                                        <div className="hidden md:block">
                                            <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Explanation of cryptography for a second grader.<br/><br/>👇<br/>Cryptography is like secret codes for computers! It helps keep messages safe by turning them into jumbled-up puzzles that only the right person can solve. So, your secrets stay secret!🕵️🔒<a href="https://twitter.com/hashtag/cryptography?src=hash&amp;ref_src=twsrc%5Etfw">#cryptography</a> <a href="https://twitter.com/hashtag/cryptocurrency?src=hash&amp;ref_src=twsrc%5Etfw">#cryptocurrency</a></p>&mdash; Kiruba (@kiruba_2) <a href="https://twitter.com/kiruba_2/status/1678853782514311169?ref_src=twsrc%5Etfw">July 11, 2023</a></blockquote>
                                        </div>

                                        <div className="hidden md:block">
                                          <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Quantum computing meets JavaScript 🚀<br/><br/>With frameworks like Qiskit.js (<a href="https://twitter.com/qiskit?ref_src=twsrc%5Etfw">@qiskit</a>), developers can explore quantum algorithms, simulate quantum circuits, and get a taste of the quantum realm using JavaScript. <a href="https://twitter.com/hashtag/QuantumComputing?src=hash&amp;ref_src=twsrc%5Etfw">#QuantumComputing</a> <a href="https://twitter.com/hashtag/JavaScript?src=hash&amp;ref_src=twsrc%5Etfw">#JavaScript</a></p>&mdash; Kiruba (@kiruba_2) <a href="https://twitter.com/kiruba_2/status/1678028229310054401?ref_src=twsrc%5Etfw">July 9, 2023</a></blockquote> 
                                        </div>

                                   
                                  
                                        
                                        {/* <div>
                                            <TwitterTweetEmbed tweetId={'1677772889318367232'}/>
                                        </div>
                                        <div>
                                            <TwitterTweetEmbed tweetId={'1678853782514311169'}/>
                                        </div>
                                        <div className='hidden md:block'>
                                            <TwitterTweetEmbed tweetId={'1677772889318367232'}/>
                                        </div>
                                        <div className='hidden md:block'>
                                            <TwitterTweetEmbed tweetId={'1678853782514311169'}/>
                                        </div>
                                        <div className='hidden md:block'>
                                            <TwitterTweetEmbed tweetId={'1677772889318367232'}/>
                                        </div> */}

                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                </div>
                {/* <div className='mt-5 xl:mt-10 z-40 flex flex-col relative items-center'>
                             <div className='px-5 xl:px-8  py-1.5 xl:py-2 bg-gray-200 bg-opacity-75 rounded-full mb-3'>
                                <span className='font-rubik text-sm xl:text-base font-medium text-slate-800'>In action ⚡️</span>
                            </div>
                            <div className='flex flex-row justify-center items-start space-x-5 w-full'>
                                <div className='min-w-[20%]'>
                                    <TwitterTweetEmbed tweetId={'1677770202908626944'}/>
                                </div>
                                <div className='min-w-[20%]'>
                                     <TwitterTweetEmbed tweetId={'1677772889318367232'}/>
                                </div>
                                <div className='min-w-[20%]'>
                                     <TwitterTweetEmbed tweetId={'1678853782514311169'}/>
                                </div>
                             </div>
                </div> */}
         
                <div className='flex flex-col xl:w-full items-center pt-12 z-40 pb-8 relative'>
                            {/* <div className='px-5 xl:px-8  py-1.5 xl:py-2 bg-gray-200 bg-opacity-75 rounded-full mb-3'>
                                <span className='font-rubik text-sm xl:text-base font-medium text-slate-800'>All in one solution 💡</span>
                            </div> */}
                            <span className='font-rubik w-[90%] xl:w-full text-3xl xl:text-5xl font-bold text-slate-800 text-center'>Take your tweets to the next level.</span>
                            <span className='font-rubik w-[90%] xl:w-full 2xl:w-[30%] text-sm xl:text-xl font-normal mt-1 text-slate-500 text-center'>Unleash the Power of Artificial Intelligence to Craft Engaging Tweets and Amplify Your Social Media Influence.</span>
                </div>
                
                <div className='mt-5 xl:mt-5 z-40 flex flex-col relative items-center pb-5'>
                    <Section tag='AI-Generated Tweets' title='Personalized and Captivating Content' description='Harness the power of artificial intelligence to create highly tailored tweets that resonate with your audience. Our app generates engaging content that aligns with your desired tone and captures attention, helping you make a lasting impact on social media.' flip/>
                    <Section tag='Enhanced Visual Storytelling' title='Perfect Captions for Images and Videos' description='Elevate your visual content with compelling tweet captions. Upload your images or videos to TweetBotAI, and our AI-powered system will generate captivating and concise captions that beautifully complement your visuals, making your tweets stand out from the crowd.'/>
                    <Section tag='Tailored Tone and Style' title='Your Voice, Amplified' description='Define your unique online persona with TweetBotAI. Choose from a range of tones, including witty, professional, friendly, and more. Our AI understands your preferences and generates tweets that match your desired style, effectively reflecting your personality and brand.' flip/>
                    <Section tag='Keyword Optimization' title='Boost Discoverability' description='Maximize the visibility of your tweets with keyword optimization. TweetBotAI intelligently incorporates relevant keywords and hashtags into your tweets, increasing the chances of discovery and engagement. Gain more followers, likes, and retweets with optimized content.'/>
                    <Section tag='Effortless Tweet Creation' title='Save Time and Energy' description="Say goodbye to writer's block and time-consuming brainstorming sessions. With TweetBotAI, effortlessly generate tweet ideas in seconds. Spend more time engaging with your audience and growing your social media presence while our AI takes care of the content creation." flip/>
                </div>
                
                <div ref={_bottom_ref} className='flex flex-col items-center pt-14 z-40 relative mt-4 bg-slate-50 pb-14  overflow-hidden'>
                            <div className='px-5 xl:px-8  py-1.5 xl:py-2 bg-gray-200 bg-opacity-75 rounded-full mb-2 xl:mb-3'>
                                <span className='font-rubik text-sm xl:text-base font-medium  text-slate-800'>Last call Baby! 🚀</span>
                            </div>
                            <div    className='flex flex-col items-center justify-center'>
                                <span  className='font-rubik w-[90%] md:w-[80%] 2xl:w-full text-3xl xl:text-5xl font-bold text-slate-800 text-center'>Try now.</span>
                                <span className='font-rubik w-[90%] md:w-[80%] xl:w-[50%] 2xl:w-[55%] text-sm md:text-base xl:text-xl font-normal mt-1 text-slate-500 text-center'>Get ready to supercharge your tweets with TweetBotAI! Boost engagement, save time, and establish your online presence effortlessly. Get started now and tweet like a pro!</span>
                            </div>
                            <m.div onClick={_on_click}
                            whileHover={{scale:1.05}} initial={{scale:1}}  
                            className='bg-[#27ae60] px-6 py-2 xl:px-10 xl:py-3 cursor-pointer rounded-full mt-5 xl:mt-10'>
                                    <span className='text-white font-rubik font-medium text-base xl:text-xl'>Get started, It's free</span>
                            </m.div>
                      <img  className='absolute opacity-70 w-full h-full -z-10 left-0 right-0 top-0 bottom-0 object-cover' src="https://framerusercontent.com/images/vOWJYr7vVzMYxY4MyToZ8ijoN8.jpg" alt="" srcSet="https://framerusercontent.com/images/vOWJYr7vVzMYxY4MyToZ8ijoN8.jpg?scale-down-to=512 512w, https://framerusercontent.com/images/vOWJYr7vVzMYxY4MyToZ8ijoN8.jpg?scale-down-to=1024 1024w, https://framerusercontent.com/images/vOWJYr7vVzMYxY4MyToZ8ijoN8.jpg 2048w" sizes="1200px" />

                </div>

                <div className='p-10 bg-slate-100 flex flex-row items-center justify-center'>
                        <span className='font-ubuntu text-slate-700 text-lg'>📧 support@tweetbotai.com</span>
                </div>

               
        </div>
    )
};




const Section = ({title,tag,description, flip}:{flip?:boolean,title:string,tag:string,description:string}) => {
    return (
        <div 
        className={`flex w-full  xl:w-[85%] 2xl:w-[60%] p-5 xl:p-10 !pb-4 relative flex-col ${flip?'md:flex-row':'md:flex-row-reverse'}`}>
            <img             alt='section' className='w-full md:w-[45%]' src='https://framerusercontent.com/images/TwojwVpws1RqKxAvxHAyjxy7OY.png?scale-down-to=4096'/>

            <div className={`flex mt-5 md:mt-0 flex-col w-full justify-center  ${flip?'md:ml-8 xl:ml-16':'md:mr-8 xl:mr-16'}`}>
                    <div className='flex flex-col mb-6'>
                        <span className='font-rubik font-bold text-base xl:text-lg 2xl:text-xl text-pink-600 mb-0.5'>{tag}</span>
                        <span className='font-rubik font-bold text-3xl xl:text-4xl 2xl:text-5xl xl:leading-[3.2rem] text-slate-800 text-left'>{title}</span>
                    </div>
                    <span className='font-rubik font-normal text-base mt-1 md:mt-5 xl:mt-0 xl:text-lg 2xl:text-xl text-slate-500 text-left'>{description}</span>
            </div>

            <div className={`absolute blur-md -z-10 left-0 top-0 right-0 bottom-0 ${flip?' -rotate-120':''} opacity-60`}>
                        <img alt='bg' className='w-full h-full' src={require('../../assests/bg/bg.webp')}/>
            </div>
        </div>
    );
};


// const Header = () => {
//     return (
//         <div className='flex flex-col justify-center items-center px-10 p-4 bg-opacity-1 z-40'>
//             <img className='w-10 h-10' src={require('../../assests/logo/logo.png')}/>
//         {/* // className='bg-white bg-opacity-60  border-b z-50 p-4 px-10 flex items-center justify-end sticky top-0 '> */}
//                 {/* <div className='bg-black px-6 py-2 rounded-lg self-end'>
//                         <span className='text-white font-ubuntu font-medium'>Get started</span>
//                 </div> */}
//         </div>
//     )
// };

export default Landing;
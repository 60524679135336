
// import React from 'react';
// import {  useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
// import { RootState } from '../../db/store';
// import { default_path } from '../../navigations/paths';

// 
// const onboarding_paths:any[] = [];

function RequireAuth({ children,replace }: { children?: JSX.Element|null,replace?:string }):any {
    // const {uid:user} = useSelector((state:RootState)=>state.auth);
    let location = useLocation();

    // if (!user&&!onboarding_paths.includes(location.pathname)) {

    //   return <Navigate to={login_path+location.search} state={{ from: location }} replace />;
    // };
    if(replace){
      return <Navigate to={`${replace}`} state={{ from: location }}  replace />;
    }
    if(!children){
      return <div/>
    };
    return children;
};


export default RequireAuth;
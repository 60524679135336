import { FC } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { domAnimation, LazyMotion } from 'framer-motion';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './db/store';


const container:HTMLElement|null = document.getElementById('root');
if(!container){
  throw new Error("container not found");
};

const root = createRoot(container);
const persistor = persistStore(store);

const Wrapper:FC = () => {
  return (
    // <React.StrictMode>
      <LazyMotion strict features={domAnimation}>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                        <App />
              </PersistGate>
          </Provider>
     </LazyMotion>
  )
};




root.render(<Wrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// AITweetLab
// AIThreadLab
// TweetBotAI
// ThreadBotAI

// ThreadMasterAI